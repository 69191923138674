import http from '@/utils/httpRequest'

// 列表获取
export function getList(params) {
  return http({
          url: http.adornUrl('/dataflow/olxareaauthmain/list'),
          method: 'get',
          params: params
        })
} 

// 删除数据
export function deleteData(ids = []) {
	return http({
            url: http.adornUrl("/dataflow/olxareaauthmain/delete"),
            method: "post",
            data: http.adornData(ids, false)
          })
}

// 根据ID 获取
export function getById(id) {
	return http({
        url: http.adornUrl(`/dataflow/olxareaauthmain/info/${id}`),
        method: "get"
        })
}
 
// 更新或新增
export function saveOrUpdate(data) {
	return http({
            url: http.adornUrl(
              `/dataflow/olxareaauthmain/${!data.id ? "save" : "update"}`
            ),
            method: "post",
            data: data
        })
}
//授权对象
export function selectAllCode(code) {
    return http({
        url: http.adornUrl(`/sys/region/childCode/${code}`),
        method: "get"
    })
}
//授权对象可查看辖区
export function pitchOnCode(code,authorizationCode) {
    return http({
        url: http.adornUrl(`/sys/region/pitchOnCode/${code}/${authorizationCode}`),
        method: "get"
    })
}

//修改使用状态
export function updateType(id) {
    return http({
        url: http.adornUrl(`/dataflow/olxareaauthmain/updateType/${id}`),
        method: "get"
    })
}
