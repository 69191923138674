<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : (flag == 'look' ? '查看' : '修改')"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :disabled="flag=='look'" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
    <el-form-item label="指标名称" prop="orkName">
      <el-input v-model.trim="dataForm.orkName" placeholder="指标名称"></el-input>
    </el-form-item>
    <el-form-item label="状态" prop="orkState">
      <!-- <sysDictSelect v-model="dataForm.orkState" dictType="management_index_state" disabled placeholder="状态"></sysDictSelect> -->
      <sysDict type="management_index_state" :value="dataForm.orkState"></sysDict>
    </el-form-item>
<!--      <el-form-item label="指标目标状态" prop="okrType">-->
<!--        <sysDictSelect v-model="dataForm.okrType" dictType="indicator_target_status" placeholder="指标目标状态"></sysDictSelect>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="指标目标" prop="okrTarget" >-->
<!--        <el-input-number  v-model="dataForm.okrTarget"  :min="0" placeholder="请输入指标目标数"></el-input-number>-->
<!--      </el-form-item>-->

      <el-form-item label="新增用户" prop="addUserNumber" >
      <el-input-number  v-model="dataForm.addUserNumber"  :min="0" placeholder="请输入新增用户数"></el-input-number>
      </el-form-item>
      <el-form-item label="增值收入" prop="valueAddedIncome" >
        <el-input-number  v-model="dataForm.valueAddedIncome"  :min="0" placeholder="请输入增值收入数"></el-input-number>
      </el-form-item>
      <el-form-item label="大众业务收入" prop="publicBusinessIncome" >
        <el-input-number  v-model="dataForm.publicBusinessIncome"  :min="0" placeholder="请输入大众业务收入数"></el-input-number>
      </el-form-item>
      <el-form-item label="5G用户发展用户" prop="developmentUser" >
        <el-input-number  v-model="dataForm.developmentUser"  :min="0" placeholder="请输入5G用户发展用户数"></el-input-number>
      </el-form-item>

    <el-form-item label="指标年份" prop="orkYear">
<!--      <el-input v-model="dataForm.orkYear" placeholder="指标年份"></el-input>-->
      <el-date-picker
          v-model="dataForm.orkYear"
          type="year"
          value-format='yyyy'
          placeholder="选择年">
      </el-date-picker>
    </el-form-item>
    <el-form-item label="填写人" prop="userName">
      <el-input v-model="dataForm.userName" disabled placeholder="填写人"></el-input>
    </el-form-item>
    <el-form-item label="填写时间" prop="fillDate">
      <el-date-picker
          v-model="dataForm.fillDate"
          type="date"
          value-format='yyyy-MM-dd'
          placeholder="选择日期">
      </el-date-picker>
      </el-form-item>
    <el-form-item label="执行时间" prop="execDate">
<!--      <el-input v-model="dataForm.execDate" placeholder="执行时间"></el-input>-->
      <el-date-picker
          v-model="dataForm.execDate"
          type="date"
          value-format='yyyy-MM-dd'
          placeholder="选择日期">
      </el-date-picker>
    </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer" v-loading="isOnSubmit" v-if="flag != 'look'">
     <!-- <el-button @click="dataFormSubmit(1)" :disabled="this.dataForm.orkState=='02'">下发</el-button> -->
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit(2)" :disabled="isOnSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getById , saveOrUpdate } from '@/api/dataflow/olxpokrmanagemain.js'
import {dateFormat} from "../../../../utils";
import { selectAllCode } from '@/api/dataflow/olxareaauthmain.js'
  export default {
    data () {
      return {
        visible: false,
        isOnSubmit: false,
        flag:'',
        selfAreaCodeList:[],
        dataForm: {
          id: null,
          orkName: '',
          orkState: '',
          okrType: '',
          okrTarget: '',
          addUserNumber: '',
          valueAddedIncome: '',
          publicBusinessIncome: '',
          developmentUser: '',
          orkYear: '',
          userId: '',
          areaCode: '',
          fillDate: '',
          transDate: '',
          execDate: '',
          userName:''
        },
        dataRule: {
          orkName: [
            { required: true, message: '指标名称不能为空', trigger: 'blur' },
            {max:225,message:'输入长度过长，请检查', trigger: 'blur'}
          ],
          // orkState: [
          //   { required: true, message: '状态', trigger: 'blur' }
          // ],
          // okrType: [
          //   { required: true, message: '状态', trigger: 'blur' }
          // ],
          // okrTarget: [
          //   { required: true, message: '状态', trigger: 'blur' }
          // ],
          orkYear: [
            { required: true, message: '指标年份不能为空', trigger: 'blur' }
          ],
          userName: [
            { required: true, message: '填写人不能为空', trigger: 'blur' }
          ],
          areaCode: [
            { required: true, message: '下级区域不能为空', trigger: 'blur' }
          ],
          fillDate: [
            { required: true, message: '填写时间不能为空', trigger: 'blur' }
          ],
          transDate: [
            { required: true, message: '下发时间不能为空', trigger: 'blur' }
          ],
          execDate: [
            { required: true, message: '执行时间不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      init (id,flag) {
        this.dataForm.id = id
        this.visible = true
        this.isOnSubmit = false
        this.flag=flag
        this.dataForm.fillDate = dateFormat(new Date,'yyyy-MM-dd')
        this.dataForm.orkYear = dateFormat(new Date,'yyyy')
        this.dataForm.userName = this.$cookie.get("Username");
        this.dataForm.orkState = '01';
        //获取登录时的ID
        const code = '1000'
        this.getSelfAreaCode(code)
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            getById(id).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.orkName = data.data.orkName
                this.dataForm.orkState = data.data.orkState
                this.dataForm.okrType = data.data.okrType
                this.dataForm.okrTarget = data.data.okrTarget
                this.dataForm.addUserNumber = data.data.addUserNumber
                this.dataForm.valueAddedIncome = data.data.valueAddedIncome
                this.dataForm.publicBusinessIncome = data.data.publicBusinessIncome
                this.dataForm.developmentUser = data.data.developmentUser
                this.dataForm.orkYear = data.data.orkYear
                this.dataForm.userName = data.data.userName
                this.dataForm.areaCode = data.data.areaCode
                this.dataForm.fillDate = data.data.fillDate
                this.dataForm.transDate = data.data.transDate
                this.dataForm.execDate = data.data.execDate
              }
            }).catch((err) => {
              this.$message.error(err)
            })
          }
        })
      },
      //更改状态
      changeState(){
        //var issue = '02';
        this.dataForm.orkState = '02';

      },
      //所属区域
      getSelfAreaCode(code) {
        selectAllCode(code).then(({data}) => {
          this.selfAreaCodeList = data.data
        })
      },
      // 表单提交
      dataFormSubmit (a) {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {

            if(a==1){
              this.dataForm.orkState = '02';
            }

          	this.isOnSubmit = true
            const data_ = {
              'id': this.dataForm.id || undefined,
			        'orkName': this.dataForm.orkName,
			        'orkState': this.dataForm.orkState,
			        'okrType': this.dataForm.okrType,
			        'okrTarget': this.dataForm.okrTarget,
			        'addUserNumber': this.dataForm.addUserNumber,
			        'valueAddedIncome': this.dataForm.valueAddedIncome,
			        'publicBusinessIncome': this.dataForm.publicBusinessIncome,
			        'developmentUser': this.dataForm.developmentUser,
			        'orkYear': this.dataForm.orkYear,
			        'userName': this.dataForm.userName,
			        'areaCode': this.dataForm.areaCode,
			        'fillDate': this.dataForm.fillDate,
			        'transDate': this.dataForm.transDate,
			        'execDate': this.dataForm.execDate
            }
            saveOrUpdate(data_).then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 500
                })
                this.visible = false
                this.$emit('refreshDataList')
              } else {
                this.$message.error(data.msg)
              }
            }).catch((err) => {
              this.$message.error(err)
            }).finally(()=>{
              this.isOnSubmit = false
            })
          }
        })
      }
    }
  }
</script>
